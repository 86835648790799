import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// SCSS
import { gutter } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const IPPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="Intellectual Property" />
      <RunReCaptcha action="ip" />
      <div className={gutter}>
        <h1>Intellectual Property</h1>

        <h2>Patents</h2>
        <p>
          Company scientists, either individually or collectively, hold over 400
          patents. NanoPharmaceuticals LLC has a very broad-based portfolio of
          57 patent applications, 34 of which have already been allowed.
        </p>

        <h2>Orphan Drug Designations</h2>
        <p>
          The FDA has granted the Company Orphan Drug Designations for its
          treatment modalities for glioblastoma, pancreatic cancer, AML and
          neuroblastoma.
        </p>

        <h2>Pediatric Voucher</h2>
        <p>
          If and when the Company’s compound for neuroblastoma obtains approval
          for commercial sale, NanoPharmaceuticals anticipates receiving a
          priority review voucher from the FDA.
        </p>
      </div>
    </Layout>
  );
};

export default IPPage;
